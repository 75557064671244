import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";

export default (props) => (
   <Layout>
      <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} className={'frank-text'} />
   </Layout>
)


export const aboutPageQuery = graphql`
    query aboutPage {
        markdownRemark(frontmatter: { pageContent: { eq: "about" } }) {
            html
            frontmatter {
                title
            }
        }
    }
`